
table {
    border-collapse: collapse;
    border-spacing: 0;
    vertical-align: middle;
    width: 100%;
    font-size: 12px;
}

tbody{
    background: 0 0;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
table tbody tr, table thead tr {
    border: 1px solid #e1e1e1;
}
.vessel-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.vessel-table td, .vessel-table th {
    text-align: left;
}
.vessel-shedules-link{
    color: #000;
}


@media screen and (max-width: 992px) {
    .vessel-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

}

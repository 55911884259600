/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 1 through 500 {
    .mt-#{2 * $i}{margin-top: 2px * $i;}
    .mt--#{2 * $i}{margin-top: -2px * $i;}
}
.mt-0 {margin-top: 0 !important;}
.mt-2 {margin-top: 2px !important;}
.mt-5 {margin-top: 5px !important;}

/*-- Margin Bottom --*/
@for $i from 1 through 500 {
    .mb-#{2 * $i}{margin-bottom: 2px *$i;}
    .mb--#{2 * $i}{margin-bottom: -2px *$i;}
}
.mb-0 {margin-bottom: 0 !important;}
.mb-2 {margin-bottom: 2px !important;}
.mb-5 {margin-bottom: 5px !important;}

/*-- Margin Left --*/
@for $i from 1 through 500 {
    .ml-#{2 * $i}{margin-left: 2px * $i;}
    .ml--#{2 * $i}{margin-left: -2px * $i;}
}
.ml-0 {margin-left: 0 !important;}
.ml-2 {margin-left: 2px !important;}
.ml-5 {margin-left: 5px !important;}

/*-- Margin Right --*/
@for $i from 1 through 500 {
    .mr-#{2 * $i}{margin-right: 2px *$i;}
    .mr--#{2 * $i}{margin-right: -2px *$i;}
}
.mr-0 {margin-right: 0 !important;}
.mr-2 {margin-right: 2px !important;}
.mr-5 {margin-right: 5px !important;}

/*-- Padding Top --*/
@for $i from 1 through 500 {
    .pt-#{2 * $i}{padding-top: 2px *$i;}
}
.pt-0 {padding-top: 0 !important;}
.pt-2 {padding-top: 2px !important;}
.pt-5 {padding-top: 5px !important;}

/*-- Padding Bottom --*/
@for $i from 1 through 500 {
    .pb-#{2 * $i}{padding-bottom: 2px *$i;}
}
.pb-0 {padding-bottom: 0 !important;}
.pb-2 {padding-bottom: 2px !important;}
.pb-5 {padding-bottom: 5px !important;}

/*-- Padding Left --*/
@for $i from 1 through 500 {
    .pl-#{2 * $i}{padding-left: 2px *$i;}
}
.pl-0 {padding-left: 0 !important;}
.pl-2 {padding-left: 2px !important;}
.pl-5 {padding-left: 5px !important;}

/*-- Padding Right --*/
@for $i from 1 through 500 {
    .pr-#{2 * $i}{padding-right: 2px *$i;}
}
.pr-0 {padding-right: 0 !important;}
.pr-2 {padding-right: 2px !important;}
.pr-5 {padding-right: 5px !important;}

/* Lg Device :1199px. */
@media #{$lg} {
    /*--
        - Large Device Margin & Padding
    -----------------------------------------*/
    /*-- Margin Top --*/
    @for $i from 1 through 500 {
        .lg-mt-#{2 * $i}{margin-top: 2px * $i;}
        .lg-mt--#{2 * $i}{margin-top: -2px * $i;}
    }
    .lg-mt-0 {margin-top: 0 !important;}
    .lg-mt-2 {margin-top: 2px !important;}
    .lg-mt-5 {margin-top: 5px !important;}

    /*-- Margin Bottom --*/
    @for $i from 1 through 500 {
        .lg-mb-#{2 * $i}{margin-bottom: 2px *$i;}
        .lg-mb--#{2 * $i}{margin-bottom: -2px *$i;}
    }
    .lg-mb-0 {margin-bottom: 0 !important;}
    .lg-mb-2 {margin-bottom: 2px !important;}
    .lg-mb-5 {margin-bottom: 5px !important;}

    /*-- Margin Left --*/
    @for $i from 1 through 500 {
        .lg-ml-#{2 * $i}{margin-left: 2px * $i;}
        .lg-ml--#{2 * $i}{margin-left: -2px * $i;}
    }
    .lg-ml-0 {margin-left: 0 !important;}
    .lg-ml-2 {margin-left: 2px !important;}
    .lg-ml-5 {margin-left: 5px !important;}

    /*-- Margin Right --*/
    @for $i from 1 through 500 {
        .lg-mr-#{2 * $i}{margin-right: 2px *$i;}
        .lg-mr--#{2 * $i}{margin-right: -2px *$i;}
    }
    .lg-mr-0 {margin-right: 0 !important;}
    .lg-mr-2 {margin-right: 2px !important;}
    .lg-mr-5 {margin-right: 5px !important;}

    /*-- Padding Top --*/
    @for $i from 1 through 500 {
        .lg-pt-#{2 * $i}{padding-top: 2px *$i;}
    }
    .lg-pt-0 {padding-top: 0 !important;}
    .lg-pt-2 {padding-top: 2px !important;}
    .lg-pt-5 {padding-top: 5px !important;}

    /*-- Padding Bottom --*/
    @for $i from 1 through 500 {
        .lg-pb-#{2 * $i}{padding-bottom: 2px *$i;}
    }
    .lg-pb-0 {padding-bottom: 0 !important;}
    .lg-pb-2 {padding-bottom: 2px !important;}
    .lg-pb-5 {padding-bottom: 5px !important;}

    /*-- Padding Left --*/
    @for $i from 1 through 500 {
        .lg-pl-#{2 * $i}{padding-left: 2px *$i;}
    }
    .lg-pl-0 {padding-left: 0 !important;}
    .lg-pl-2 {padding-left: 2px !important;}
    .lg-pl-5 {padding-left: 5px !important;}

    /*-- Padding Right --*/
    @for $i from 1 through 500 {
        .lg-pr-#{2 * $i}{padding-right: 2px *$i;}
    }
    .lg-pr-0 {padding-right: 0 !important;}
    .lg-pr-2 {padding-right: 2px !important;}
    .lg-pr-5 {padding-right: 5px !important;}
}

@media #{$md} {
    /*--
        - MD Device Margin & Padding
    -----------------------------------------*/
    /*-- Margin Top --*/
    @for $i from 1 through 500 {
        .md-mt-#{2 * $i}{margin-top: 2px * $i;}
        .md-mt--#{2 * $i}{margin-top: -2px * $i;}
    }
    .md-mt-0 {margin-top: 0 !important;}
    .md-mt-2 {margin-top: 2px !important;}
    .md-mt-5 {margin-top: 5px !important;}

    /*-- Margin Bottom --*/
    @for $i from 1 through 500 {
        .md-mb-#{2 * $i}{margin-bottom: 2px *$i;}
        .md-mb--#{2 * $i}{margin-bottom: -2px *$i;}
    }
    .md-mb-0 {margin-bottom: 0 !important;}
    .md-mb-2 {margin-bottom: 2px !important;}
    .md-mb-5 {margin-bottom: 5px !important;}

    /*-- Margin Left --*/
    @for $i from 1 through 500 {
        .md-ml-#{2 * $i}{margin-left: 2px * $i;}
        .md-ml--#{2 * $i}{margin-left: -2px * $i;}
    }
    .md-ml-0 {margin-left: 0 !important;}
    .md-ml-2 {margin-left: 2px !important;}
    .md-ml-5 {margin-left: 5px !important;}

    /*-- Margin Right --*/
    @for $i from 1 through 500 {
        .md-mr-#{2 * $i}{margin-right: 2px *$i;}
        .md-mr--#{2 * $i}{margin-right: -2px *$i;}
    }
    .md-mr-0 {margin-right: 0 !important;}
    .md-mr-2 {margin-right: 2px !important;}
    .md-mr-5 {margin-right: 5px !important;}

    /*-- Padding Top --*/
    @for $i from 1 through 500 {
        .md-pt-#{2 * $i}{padding-top: 2px *$i;}
    }
    .md-pt-0 {padding-top: 0 !important;}
    .md-pt-2 {padding-top: 2px !important;}
    .md-pt-5 {padding-top: 5px !important;}

    /*-- Padding Bottom --*/
    @for $i from 1 through 500 {
        .md-pb-#{2 * $i}{padding-bottom: 2px *$i;}
    }
    .md-pb-0 {padding-bottom: 0 !important;}
    .md-pb-2 {padding-bottom: 2px !important;}
    .md-pb-5 {padding-bottom: 5px !important;}

    /*-- Padding Left --*/
    @for $i from 1 through 500 {
        .md-pl-#{2 * $i}{padding-left: 3px *$i;}
    }
    .md-pl-0 {padding-left: 0 !important;}
    .md-pl-2 {padding-left: 2px !important;}
    .md-pl-5 {padding-left: 5px !important;}

    /*-- Padding Right --*/
    @for $i from 1 through 500 {
        .md-pr-#{2 * $i}{padding-right: 2px *$i;}
    }
    .md-pr-0 {padding-right: 0 !important;}
    .md-pr-2 {padding-right: 2px !important;}
    .md-pr-5 {padding-right: 5px !important;}
}

@media #{$sm} {
    /*--
        - SM Device Margin & Padding
    -----------------------------------------*/
    /*-- Margin Top --*/
    @for $i from 1 through 500 {
        .sm-mt-#{2 * $i}{margin-top: 2px * $i;}
        .sm-mt--#{2 * $i}{margin-top: -2px * $i;}
    }
    .sm-mt-0 {margin-top: 0 !important;}
    .sm-mt-2 {margin-top: 2px !important;}
    .sm-mt-5 {margin-top: 5px !important;}

    /*-- Margin Bottom --*/
    @for $i from 1 through 500 {
        .sm-mb-#{2 * $i}{margin-bottom: 2px *$i;}
        .sm-mb--#{2 * $i}{margin-bottom: -2px *$i;}
    }
    .sm-mb-0 {margin-bottom: 0 !important;}
    .sm-mb-2 {margin-bottom: 2px !important;}
    .sm-mb-5 {margin-bottom: 5px !important;}

    /*-- Margin Left --*/
    @for $i from 1 through 500 {
        .sm-ml-#{2 * $i}{margin-left: 2px * $i;}
        .sm-ml--#{2 * $i}{margin-left: -2px * $i;}
    }
    .sm-ml-0 {margin-left: 0 !important;}
    .sm-ml-2 {margin-left: 2px !important;}
    .sm-ml-5 {margin-left: 5px !important;}

    /*-- Margin Right --*/
    @for $i from 1 through 500 {
        .sm-mr-#{2 * $i}{margin-right: 2px *$i;}
        .sm-mr--#{2 * $i}{margin-right: -2px *$i;}
    }
    .sm-mr-0 {margin-right: 0 !important;}
    .sm-mr-2 {margin-right: 2px !important;}
    .sm-mr-5 {margin-right: 5px !important;}

    /*-- Padding Top --*/
    @for $i from 1 through 500 {
        .sm-pt-#{2 * $i}{padding-top: 2px *$i;}
    }
    .sm-pt-0 {padding-top: 0 !important;}
    .sm-pt-2 {padding-top: 2px !important;}
    .sm-pt-5 {padding-top: 5px !important;}

    /*-- Padding Bottom --*/
    @for $i from 1 through 500 {
        .sm-pb-#{2 * $i}{padding-bottom: 2px *$i;}
    }
    .sm-pb-0 {padding-bottom: 0 !important;}
    .sm-pb-2 {padding-bottom: 2px !important;}
    .sm-pb-5 {padding-bottom: 5px !important;}

    /*-- Padding Left --*/
    @for $i from 1 through 500 {
        .sm-pl-#{2 * $i}{padding-left: 2px *$i;}
    }
    .sm-pl-0 {padding-left: 0 !important;}
    .sm-pl-2 {padding-left: 2px !important;}
    .sm-pl-5 {padding-left: 5px !important;}

    /*-- Padding Right --*/
    @for $i from 1 through 500 {
        .sm-pr-#{2 * $i}{padding-right: 2px *$i;}
    }
    .sm-pr-0 {padding-right: 0 !important;}
    .sm-pr-2 {padding-right: 2px !important;}
    .sm-pr-5 {padding-right: 5px !important;}
}

@media #{$xs} {
    /*--
        - XS Device Margin & Padding
    -----------------------------------------*/
    /*-- Margin Top --*/
    @for $i from 1 through 500 {
        .xs-mt-#{2 * $i}{margin-top: 2px * $i;}
        .xs-mt--#{2 * $i}{margin-top: -2px * $i;}
    }
    .xs-mt-0 {margin-top: 0 !important;}
    .xs-mt-2 {margin-top: 2px !important;}
    .xs-mt-5 {margin-top: 5px !important;}

    /*-- Margin Bottom --*/
    @for $i from 1 through 500 {
        .xs-mb-#{2 * $i}{margin-bottom: 2px *$i;}
        .xs-mb--#{2 * $i}{margin-bottom: -2px *$i;}
    }
    .xs-mb-0 {margin-bottom: 0 !important;}
    .xs-mb-2 {margin-bottom: 2px !important;}
    .xs-mb-5 {margin-bottom: 5px !important;}

    /*-- Margin Left --*/
    @for $i from 1 through 500 {
        .xs-ml-#{2 * $i}{margin-left: 2px * $i;}
        .xs-ml--#{2 * $i}{margin-left: -2px * $i;}
    }
    .xs-ml-0 {margin-left: 0 !important;}
    .xs-ml-2 {margin-left: 2px !important;}
    .xs-ml-5 {margin-left: 5px !important;}

    /*-- Margin Right --*/
    @for $i from 1 through 500 {
        .xs-mr-#{2 * $i}{margin-right: 2px *$i;}
        .xs-mr--#{2 * $i}{margin-right: -2px *$i;}
    }
    .xs-mr-0 {margin-right: 0 !important;}
    .xs-mr-2 {margin-right: 2px !important;}
    .xs-mr-5 {margin-right: 5px !important;}

    /*-- Padding Top --*/
    @for $i from 1 through 500 {
        .xs-pt-#{2 * $i}{padding-top: 2px *$i;}
    }
    .xs-pt-0 {padding-top: 0 !important;}
    .xs-pt-2 {padding-top: 2px !important;}
    .xs-pt-5 {padding-top: 5px !important;}

    /*-- Padding Bottom --*/
    @for $i from 1 through 500 {
        .xs-pb-#{2 * $i}{padding-bottom: 2px *$i;}
    }
    .xs-pb-0 {padding-bottom: 0 !important;}
    .xs-pb-2 {padding-bottom: 2px !important;}
    .xs-pb-5 {padding-bottom: 5px !important;}

    /*-- Padding Left --*/
    @for $i from 1 through 500 {
        .xs-pl-#{2 * $i}{padding-left: 2px *$i;}
    }
    .xs-pl-0 {padding-left: 0 !important;}
    .xs-pl-2 {padding-left: 2px !important;}
    .xs-pl-5 {padding-left: 5px !important;}

    /*-- Padding Right --*/
    @for $i from 1 through 500 {
        .xs-pr-#{2 * $i}{padding-right: 2px *$i;}
    }
    .xs-pr-0 {padding-right: 0 !important;}
    .xs-pr-2 {padding-right: 2px !important;}
    .xs-pr-5 {padding-right: 5px !important;}
}

